* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212b36;
  font-weight: 400;
  font-size: 14px;
}

code {
  font-family: "Open Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
